"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlotToJSON = exports.SlotFromJSON = void 0;
function SlotFromJSON(json) {
    return {
        'startTime': json['start_time'],
        'endTime': json['end_time'],
        'available': json['available'],
    };
}
exports.SlotFromJSON = SlotFromJSON;
function SlotToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'start_time': value.startTime,
        'end_time': value.endTime,
        'available': value.available,
    };
}
exports.SlotToJSON = SlotToJSON;
