"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserShipperRoleEnum = exports.UserToJSON = exports.UserFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function UserFromJSON(json) {
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'firstName': !runtime_1.exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !runtime_1.exists(json, 'last_name') ? undefined : json['last_name'],
        'password': json['password'],
        'shipper': !runtime_1.exists(json, 'shipper') ? undefined : _1.ShipperFromJSON(json['shipper']),
        'shipperId': !runtime_1.exists(json, 'shipper_id') ? undefined : json['shipper_id'],
        'shipperRole': !runtime_1.exists(json, 'shipper_role') ? undefined : json['shipper_role'],
    };
}
exports.UserFromJSON = UserFromJSON;
function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'password': value.password,
        'shipper': _1.ShipperToJSON(value.shipper),
        'shipper_id': value.shipperId,
        'shipper_role': value.shipperRole,
    };
}
exports.UserToJSON = UserToJSON;
/**
* @export
* @enum {string}
*/
var UserShipperRoleEnum;
(function (UserShipperRoleEnum) {
    UserShipperRoleEnum["RegularUser"] = "Regular User";
    UserShipperRoleEnum["Administrator"] = "Administrator";
})(UserShipperRoleEnum = exports.UserShipperRoleEnum || (exports.UserShipperRoleEnum = {}));
