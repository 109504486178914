"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePasswordToJSON = exports.ChangePasswordFromJSON = void 0;
function ChangePasswordFromJSON(json) {
    return {
        'currentPassword': json['current_password'],
        'newPassword': json['new_password'],
    };
}
exports.ChangePasswordFromJSON = ChangePasswordFromJSON;
function ChangePasswordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'current_password': value.currentPassword,
        'new_password': value.newPassword,
    };
}
exports.ChangePasswordToJSON = ChangePasswordToJSON;
