"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapValues = exports.exists = exports.COLLECTION_FORMATS = exports.RequiredError = exports.Configuration = exports.BASE_PATH = void 0;
function calculateBasePath() {
    var url = window.location.href;
    url = url.split('//')[1];
    var domain = url.split('/')[0];
    if (domain.indexOf('localhost') !== -1 || domain.indexOf('127.0.0.1') !== -1) {
        return 'http://localhost:8080';
    }
    else {
        return "https://api." + domain;
    }
}
exports.BASE_PATH = "http://localhost".replace(/\/+$/, "");
exports.Configuration = {
    basePath: calculateBasePath(),
};
console.log("api path: " + exports.BASE_PATH);
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        _this.name = "RequiredError";
        return _this;
    }
    return RequiredError;
}(Error));
exports.RequiredError = RequiredError;
exports.COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
function exists(json, key) {
    var value = json[key];
    return value !== null && value !== undefined;
}
exports.exists = exists;
function mapValues(data, fn) {
    return Object.keys(data).reduce(function (acc, key) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[key] = fn(data[key]), _a)));
    }, {});
}
exports.mapValues = mapValues;
