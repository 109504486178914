import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  listAppointmentsAdmins as listAppointmentsAdminsRaw,
  Appointment,
  ListAppointmentsAdminsRequest,
  listUsers as listUsersRaw,
  ListUsersRequest,
  User,
} from 'api-client';
import { result } from 'lodash';
import set from 'lodash/fp/set';


export const listUsers = (args: ListUsersRequest) => listUsersRaw(args, {
  transform: body => ({
    users: body.results,
    usersQuery: {
      count: body.count,
      rowsPerPage: body.limit,
      page: Math.floor(body.offset / body.limit) + 1,
    },
  }),
  update: {
    users: (prev, next) => {
      return next;
    },
    usersQuery: (prev, next) => {
      return next;
    }
  },
  force: true,
});

export const getUsers = (state:any): User[] => (state.entities.users || []);

export const listAppointmentsAdmin = (args: ListAppointmentsAdminsRequest) => listAppointmentsAdminsRaw(args, {
  transform: body => ({
    appointments: body.results,
    appointmentsQuery: {
      count: body.count,
      rowsPerPage: body.limit,
      page: Math.floor(body.offset / body.limit) + 1,
    },
  }),
  update: {
    appointments: (prev, next) => {
      return next;
    },
    appointmentsQuery: (prev, next) => {
      return next;
    }
  },
  force: true,
});

export const getAppointmentsAdmin = (state: any): Appointment[] => (state.entities.appointments || []);

export const getAppointmentsAdminQuery = (state: any): any => (state.entities.appointmentsQuery || {});


export interface AssignDockActionPayload {
  overrideTime: Date;
  dockId: string;
  appointmentId: number;
}

export const assignDockAction = createAction('ASSIGN_DOCK', (payload: AssignDockActionPayload ) => ({payload}));

export const assignDockReducer = createReducer({} as any, {
  [assignDockAction.type]: (state, {payload: {dockId, appointmentId, overrideTime}}) => {
    const appointment = state.entities.appointments.find((appointment) => appointment.id === appointmentId);
    const appointmentIndex = state.entities.appointments.findIndex((appointment) => appointment.id === appointmentId);
    if (appointmentIndex === -1) {
      return state;
    }

    const newAppointment = {
      ...appointment,
      dockAssignment: dockId,
      overrideTime: overrideTime,
    }

    return set(['entities', 'appointments', appointmentIndex], newAppointment, state);
  }
});
