"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentCheckCallsToJSON = exports.AppointmentCheckCallsFromJSON = void 0;
var runtime_1 = require("../runtime");
function AppointmentCheckCallsFromJSON(json) {
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'result': !runtime_1.exists(json, 'result') ? undefined : json['result'],
    };
}
exports.AppointmentCheckCallsFromJSON = AppointmentCheckCallsFromJSON;
function AppointmentCheckCallsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'result': value.result,
    };
}
exports.AppointmentCheckCallsToJSON = AppointmentCheckCallsToJSON;
