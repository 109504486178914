"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationDockGroupingsToJSON = exports.LocationDockGroupingsFromJSON = void 0;
var runtime_1 = require("../runtime");
function LocationDockGroupingsFromJSON(json) {
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'isInbound': !runtime_1.exists(json, 'is_inbound') ? undefined : json['is_inbound'],
        'isOutbound': !runtime_1.exists(json, 'is_outbound') ? undefined : json['is_outbound'],
        'location': !runtime_1.exists(json, 'location') ? undefined : json['location'],
        'maxCapacity': !runtime_1.exists(json, 'max_capacity') ? undefined : json['max_capacity'],
    };
}
exports.LocationDockGroupingsFromJSON = LocationDockGroupingsFromJSON;
function LocationDockGroupingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'is_inbound': value.isInbound,
        'is_outbound': value.isOutbound,
        'location': value.location,
        'max_capacity': value.maxCapacity,
    };
}
exports.LocationDockGroupingsToJSON = LocationDockGroupingsToJSON;
