

import { actionTypes } from 'redux-query';

const { MUTATE_ASYNC, REQUEST_ASYNC, REQUEST_START, MUTATE_START } = actionTypes;


export const jwtMiddleware = (store) => ((next) => ((action) => {

  const types = [MUTATE_ASYNC, REQUEST_ASYNC, REQUEST_START, MUTATE_START]
  if (action && (types.indexOf(action.type) !== -1)) {
    // This is a redux-query action so add the JWT header
    const options = action.options || {};
    const headers = options.headers || {};

    const token = localStorage.getItem('accessToken');
    if (token) {
      const updatedAction = {
        ...action,
        options: {
          ...options,
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          }
        },
      };
      
      // Let the action continue, but now with the JWT header.
      return next(updatedAction);
    } else {
      return next(action);
    }
  } else {
    // This isn't a redux-query action so just let it pass through
    return next(action);
  }
}));
