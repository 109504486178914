"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DockAssignmentAppointmentsToJSON = exports.DockAssignmentAppointmentsFromJSON = void 0;
var runtime_1 = require("../runtime");
function DockAssignmentAppointmentsFromJSON(json) {
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'dockAssignment': !runtime_1.exists(json, 'dock_assignment') ? undefined : json['dock_assignment'],
        'overrideTime': !runtime_1.exists(json, 'override_time') ? undefined : new Date(json['override_time']),
    };
}
exports.DockAssignmentAppointmentsFromJSON = DockAssignmentAppointmentsFromJSON;
function DockAssignmentAppointmentsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'dock_assignment': value.dockAssignment,
        'override_time': value.overrideTime === undefined ? undefined : value.overrideTime.toISOString(),
    };
}
exports.DockAssignmentAppointmentsToJSON = DockAssignmentAppointmentsToJSON;
