import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { entitiesReducer, queriesReducer, queryMiddleware, errorsReducer } from 'redux-query';
import reduceReducers from './reduceReducers';
import { logoutReducer } from './auth';
import { assignDockReducer } from './shipperAdmin';

export const getQueries = (state: any) => state.queries;
export const getEntities = (state: any) => state.entities;


const baseReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  entities: entitiesReducer,
  queries: queriesReducer,
  errors: errorsReducer,
});

// when we logout, we need to manually modify the redux query entities
export const rootReducer = reduceReducers(baseReducer, logoutReducer, assignDockReducer );

export default rootReducer;
