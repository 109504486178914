import React from 'react';
import type { FC } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { 
  Button,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

export interface NavItemProps {
  title: string;
  href: string;
}

export const NavItem: FC<NavItemProps> = (props: NavItemProps) => {
  const classes = useStyles();

  return (
    <Button
      activeClassName={classes.active}
      component={RouterLink}
      exact
      to={props.href}
    >
      <span className={classes.title}>
        {props.title}
      </span>
    </Button>
  );
}