"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenRefreshToJSON = exports.TokenRefreshFromJSON = void 0;
function TokenRefreshFromJSON(json) {
    return {
        'refresh': json['refresh'],
    };
}
exports.TokenRefreshFromJSON = TokenRefreshFromJSON;
function TokenRefreshToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'refresh': value.refresh,
    };
}
exports.TokenRefreshToJSON = TokenRefreshToJSON;
