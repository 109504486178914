"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationToJSON = exports.LocationFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function LocationFromJSON(json) {
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'dockGroupings': json['dock_groupings'].map(_1.LocationDockGroupingsFromJSON),
    };
}
exports.LocationFromJSON = LocationFromJSON;
function LocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'dock_groupings': value.dockGroupings.map(_1.LocationDockGroupingsToJSON),
    };
}
exports.LocationToJSON = LocationToJSON;
