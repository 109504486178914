"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentToJSON = exports.AppointmentFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function AppointmentFromJSON(json) {
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'date': !runtime_1.exists(json, 'date') ? undefined : json['date'],
        'time': !runtime_1.exists(json, 'time') ? undefined : json['time'],
        'datetime': new Date(json['datetime']),
        'dockGroupingId': json['dock_grouping_id'],
        'dockGrouping': !runtime_1.exists(json, 'dock_grouping') ? undefined : _1.LocationDockGroupingsFromJSON(json['dock_grouping']),
        'location': !runtime_1.exists(json, 'location') ? undefined : json['location'],
        'truckerEmail': !runtime_1.exists(json, 'trucker_email') ? undefined : json['trucker_email'],
        'poNumber': json['po_number'],
        'contactPhone': !runtime_1.exists(json, 'contact_phone') ? undefined : json['contact_phone'],
        'checkCalls': !runtime_1.exists(json, 'check_calls') ? undefined : json['check_calls'].map(_1.AppointmentCheckCallsFromJSON),
        'dockAssignment': !runtime_1.exists(json, 'dock_assignment') ? undefined : json['dock_assignment'],
        'overrideTime': !runtime_1.exists(json, 'override_time') ? undefined : new Date(json['override_time']),
        'shipperId': !runtime_1.exists(json, 'shipper_id') ? undefined : json['shipper_id'],
        'shipper': !runtime_1.exists(json, 'shipper') ? undefined : json['shipper'],
        'carrierName': !runtime_1.exists(json, 'carrier_name') ? undefined : json['carrier_name'],
        'bolNumber': !runtime_1.exists(json, 'bol_number') ? undefined : json['bol_number'],
        'isOutbound': !runtime_1.exists(json, 'is_outbound') ? undefined : json['is_outbound'],
    };
}
exports.AppointmentFromJSON = AppointmentFromJSON;
function AppointmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'date': value.date,
        'time': value.time,
        'datetime': value.datetime.toISOString(),
        'dock_grouping_id': value.dockGroupingId,
        'dock_grouping': _1.LocationDockGroupingsToJSON(value.dockGrouping),
        'location': value.location,
        'trucker_email': value.truckerEmail,
        'po_number': value.poNumber,
        'contact_phone': value.contactPhone,
        'check_calls': value.checkCalls === undefined ? undefined : value.checkCalls.map(_1.AppointmentCheckCallsToJSON),
        'dock_assignment': value.dockAssignment,
        'override_time': value.overrideTime === undefined ? undefined : value.overrideTime.toISOString(),
        'shipper_id': value.shipperId,
        'shipper': value.shipper,
        'carrier_name': value.carrierName,
        'bol_number': value.bolNumber,
        'is_outbound': value.isOutbound,
    };
}
exports.AppointmentToJSON = AppointmentToJSON;
