"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenObtainPairToJSON = exports.TokenObtainPairFromJSON = void 0;
function TokenObtainPairFromJSON(json) {
    return {
        'email': json['email'],
        'password': json['password'],
    };
}
exports.TokenObtainPairFromJSON = TokenObtainPairFromJSON;
function TokenObtainPairToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'password': value.password,
    };
}
exports.TokenObtainPairToJSON = TokenObtainPairToJSON;
