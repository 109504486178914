"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenResponseToJSON = exports.TokenResponseFromJSON = void 0;
var _1 = require("./");
function TokenResponseFromJSON(json) {
    return {
        'access': json['access'],
        'refresh': json['refresh'],
        'user': _1.UserFromJSON(json['user']),
    };
}
exports.TokenResponseFromJSON = TokenResponseFromJSON;
function TokenResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'access': value.access,
        'refresh': value.refresh,
        'user': _1.UserToJSON(value.user),
    };
}
exports.TokenResponseToJSON = TokenResponseToJSON;
