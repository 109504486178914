"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLocationsAdmin = exports.updateDockAssignment = exports.updateAppointment = exports.updateAdminUser = exports.retrieveUser = exports.retrieveShipper = exports.retrieveLocationsAdmin = exports.retrieveLocation = exports.retrieveDockGrouping = exports.retrieveAppointment = exports.retrieveAdminUser = exports.partialUpdateLocationsAdmin = exports.partialUpdateDockAssignment = exports.partialUpdateAppointment = exports.partialUpdateAdminUser = exports.listUsers = exports.listSlots = exports.listShippers = exports.listLocationsAdmins = exports.listLocations = exports.listInvites = exports.listHealthChecks = exports.listAppointmentsAdmins = exports.listAppointments = exports.destroyLocationsAdmin = exports.destroyAppointment = exports.destroyAdminUser = exports.createVoice = exports.createUser = exports.createTokenRefresh = exports.createTokenObtainPair = exports.createResetPassword = exports.createRequestPasswordReset = exports.createLocationsAdmin = exports.createInvitedUser = exports.createInvite = exports.createGatherHours = exports.createGather = exports.createChangePassword = exports.createAppointment = exports.createActivationCode = void 0;
var runtime = require("../runtime");
var models_1 = require("../models");
/**
 */
function createActivationCodeRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/users/activate/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.ActivationCodeToJSON(requestParameters.activationCode),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.TokenResponseFromJSON(body), text); };
    }
    return config;
}
/**
*/
function createActivationCode(requestParameters, requestConfig) {
    return createActivationCodeRaw(requestParameters, requestConfig);
}
exports.createActivationCode = createActivationCode;
/**
 */
function createAppointmentRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/appointments/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.AppointmentToJSON(requestParameters.appointment),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.AppointmentFromJSON(body), text); };
    }
    return config;
}
/**
*/
function createAppointment(requestParameters, requestConfig) {
    return createAppointmentRaw(requestParameters, requestConfig);
}
exports.createAppointment = createAppointment;
/**
 */
function createChangePasswordRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/password/change/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.ChangePasswordToJSON(requestParameters.changePassword),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.ChangePasswordFromJSON(body), text); };
    }
    return config;
}
/**
*/
function createChangePassword(requestParameters, requestConfig) {
    return createChangePasswordRaw(requestParameters, requestConfig);
}
exports.createChangePassword = createChangePassword;
/**
 */
function createGatherRaw(requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/gather/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        throw "OH NO";
    }
    return config;
}
/**
*/
function createGather(requestConfig) {
    return createGatherRaw(requestConfig);
}
exports.createGather = createGather;
/**
 */
function createGatherHoursRaw(requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/gather_hours/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        throw "OH NO";
    }
    return config;
}
/**
*/
function createGatherHours(requestConfig) {
    return createGatherHoursRaw(requestConfig);
}
exports.createGatherHours = createGatherHours;
/**
 */
function createInviteRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/admin/invites/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.InviteToJSON(requestParameters.invite),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InviteFromJSON(body), text); };
    }
    return config;
}
/**
*/
function createInvite(requestParameters, requestConfig) {
    return createInviteRaw(requestParameters, requestConfig);
}
exports.createInvite = createInvite;
/**
 */
function createInvitedUserRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling createInvitedUser.');
    }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/auth/users/invite/{id}").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.UserToJSON(requestParameters.user),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.TokenResponseFromJSON(body), text); };
    }
    return config;
}
/**
*/
function createInvitedUser(requestParameters, requestConfig) {
    return createInvitedUserRaw(requestParameters, requestConfig);
}
exports.createInvitedUser = createInvitedUser;
/**
 */
function createLocationsAdminRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/admin/locations/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.LocationToJSON(requestParameters.location),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.LocationFromJSON(body), text); };
    }
    return config;
}
/**
*/
function createLocationsAdmin(requestParameters, requestConfig) {
    return createLocationsAdminRaw(requestParameters, requestConfig);
}
exports.createLocationsAdmin = createLocationsAdmin;
/**
 */
function createRequestPasswordResetRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/password/request_reset/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.body,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        throw "OH NO";
    }
    return config;
}
/**
*/
function createRequestPasswordReset(requestParameters, requestConfig) {
    return createRequestPasswordResetRaw(requestParameters, requestConfig);
}
exports.createRequestPasswordReset = createRequestPasswordReset;
/**
 */
function createResetPasswordRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/password/reset/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.body,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        throw "OH NO";
    }
    return config;
}
/**
*/
function createResetPassword(requestParameters, requestConfig) {
    return createResetPasswordRaw(requestParameters, requestConfig);
}
exports.createResetPassword = createResetPassword;
/**
 */
function createTokenObtainPairRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/token/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.TokenObtainPairToJSON(requestParameters.tokenObtainPair),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.TokenResponseFromJSON(body), text); };
    }
    return config;
}
/**
*/
function createTokenObtainPair(requestParameters, requestConfig) {
    return createTokenObtainPairRaw(requestParameters, requestConfig);
}
exports.createTokenObtainPair = createTokenObtainPair;
/**
 * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
 */
function createTokenRefreshRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/token/refresh/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.TokenRefreshToJSON(requestParameters.tokenRefresh),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.TokenRefreshFromJSON(body), text); };
    }
    return config;
}
/**
* Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
*/
function createTokenRefresh(requestParameters, requestConfig) {
    return createTokenRefreshRaw(requestParameters, requestConfig);
}
exports.createTokenRefresh = createTokenRefresh;
/**
 */
function createUserRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/users/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || models_1.UserToJSON(requestParameters.user),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.UserFromJSON(body), text); };
    }
    return config;
}
/**
*/
function createUser(requestParameters, requestConfig) {
    return createUserRaw(requestParameters, requestConfig);
}
exports.createUser = createUser;
/**
 */
function createVoiceRaw(requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/voice/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        throw "OH NO";
    }
    return config;
}
/**
*/
function createVoice(requestConfig) {
    return createVoiceRaw(requestConfig);
}
exports.createVoice = createVoice;
/**
 */
function destroyAdminUserRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling destroyAdminUser.');
    }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/auth/admin/users/{id}").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
    }
    return config;
}
/**
*/
function destroyAdminUser(requestParameters, requestConfig) {
    return destroyAdminUserRaw(requestParameters, requestConfig);
}
exports.destroyAdminUser = destroyAdminUser;
/**
 */
function destroyAppointmentRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling destroyAppointment.');
    }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.dockGroupingIdLocationId !== undefined) {
        queryParameters['dock_grouping_id__location_id'] = requestParameters.dockGroupingIdLocationId;
    }
    if (requestParameters.datetime !== undefined) {
        queryParameters['datetime'] = requestParameters.datetime;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/appointments/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
    }
    return config;
}
/**
*/
function destroyAppointment(requestParameters, requestConfig) {
    return destroyAppointmentRaw(requestParameters, requestConfig);
}
exports.destroyAppointment = destroyAppointment;
/**
 */
function destroyLocationsAdminRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling destroyLocationsAdmin.');
    }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/admin/locations/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
    }
    return config;
}
/**
*/
function destroyLocationsAdmin(requestParameters, requestConfig) {
    return destroyLocationsAdminRaw(requestParameters, requestConfig);
}
exports.destroyLocationsAdmin = destroyLocationsAdmin;
/**
 */
function listAppointmentsRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }
    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }
    if (requestParameters.dockGroupingIdLocationId !== undefined) {
        queryParameters['dock_grouping_id__location_id'] = requestParameters.dockGroupingIdLocationId;
    }
    if (requestParameters.datetime !== undefined) {
        queryParameters['datetime'] = requestParameters.datetime;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/appointments/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InlineResponse2005FromJSON(body), text); };
    }
    return config;
}
/**
*/
function listAppointments(requestParameters, requestConfig) {
    return listAppointmentsRaw(requestParameters, requestConfig);
}
exports.listAppointments = listAppointments;
/**
 */
function listAppointmentsAdminsRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }
    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }
    if (requestParameters.dockGroupingIdLocationId !== undefined) {
        queryParameters['dock_grouping_id__location_id'] = requestParameters.dockGroupingIdLocationId;
    }
    if (requestParameters.datetime !== undefined) {
        queryParameters['datetime'] = requestParameters.datetime;
    }
    if (requestParameters.dockGroupingId !== undefined) {
        queryParameters['dock_grouping_id'] = requestParameters.dockGroupingId;
    }
    if (requestParameters.datetimeBefore !== undefined) {
        queryParameters['datetime_before'] = requestParameters.datetimeBefore;
    }
    if (requestParameters.datetimeAfter !== undefined) {
        queryParameters['datetime_after'] = requestParameters.datetimeAfter;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/admin/appointments/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InlineResponse2005FromJSON(body), text); };
    }
    return config;
}
/**
*/
function listAppointmentsAdmins(requestParameters, requestConfig) {
    return listAppointmentsAdminsRaw(requestParameters, requestConfig);
}
exports.listAppointmentsAdmins = listAppointmentsAdmins;
/**
 */
function listHealthChecksRaw(requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/health_check",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
    }
    return config;
}
/**
*/
function listHealthChecks(requestConfig) {
    return listHealthChecksRaw(requestConfig);
}
exports.listHealthChecks = listHealthChecks;
/**
 */
function listInvitesRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }
    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/admin/invites/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InlineResponse2002FromJSON(body), text); };
    }
    return config;
}
/**
*/
function listInvites(requestParameters, requestConfig) {
    return listInvitesRaw(requestParameters, requestConfig);
}
exports.listInvites = listInvites;
/**
 */
function listLocationsRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listLocations.');
    }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }
    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/shipper/{id}/locations/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InlineResponse2003FromJSON(body), text); };
    }
    return config;
}
/**
*/
function listLocations(requestParameters, requestConfig) {
    return listLocationsRaw(requestParameters, requestConfig);
}
exports.listLocations = listLocations;
/**
 */
function listLocationsAdminsRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }
    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/admin/locations/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InlineResponse2003FromJSON(body), text); };
    }
    return config;
}
/**
*/
function listLocationsAdmins(requestParameters, requestConfig) {
    return listLocationsAdminsRaw(requestParameters, requestConfig);
}
exports.listLocationsAdmins = listLocationsAdmins;
/**
 */
function listShippersRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }
    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/shippers/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InlineResponse200FromJSON(body), text); };
    }
    return config;
}
/**
*/
function listShippers(requestParameters, requestConfig) {
    return listShippersRaw(requestParameters, requestConfig);
}
exports.listShippers = listShippers;
/**
 */
function listSlotsRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listSlots.');
    }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }
    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }
    if (requestParameters.date !== undefined) {
        queryParameters['date'] = requestParameters.date;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/dockgroupings/{id}/slots/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InlineResponse2004FromJSON(body), text); };
    }
    return config;
}
/**
*/
function listSlots(requestParameters, requestConfig) {
    return listSlotsRaw(requestParameters, requestConfig);
}
exports.listSlots = listSlots;
/**
 */
function listUsersRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }
    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/admin/users/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.InlineResponse2001FromJSON(body), text); };
    }
    return config;
}
/**
*/
function listUsers(requestParameters, requestConfig) {
    return listUsersRaw(requestParameters, requestConfig);
}
exports.listUsers = listUsers;
/**
 */
function partialUpdateAdminUserRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling partialUpdateAdminUser.');
    }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/auth/admin/users/{id}").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || models_1.UserToJSON(requestParameters.user),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.UserFromJSON(body), text); };
    }
    return config;
}
/**
*/
function partialUpdateAdminUser(requestParameters, requestConfig) {
    return partialUpdateAdminUserRaw(requestParameters, requestConfig);
}
exports.partialUpdateAdminUser = partialUpdateAdminUser;
/**
 */
function partialUpdateAppointmentRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling partialUpdateAppointment.');
    }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.dockGroupingIdLocationId !== undefined) {
        queryParameters['dock_grouping_id__location_id'] = requestParameters.dockGroupingIdLocationId;
    }
    if (requestParameters.datetime !== undefined) {
        queryParameters['datetime'] = requestParameters.datetime;
    }
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/appointments/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || models_1.AppointmentToJSON(requestParameters.appointment),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.AppointmentFromJSON(body), text); };
    }
    return config;
}
/**
*/
function partialUpdateAppointment(requestParameters, requestConfig) {
    return partialUpdateAppointmentRaw(requestParameters, requestConfig);
}
exports.partialUpdateAppointment = partialUpdateAppointment;
/**
 */
function partialUpdateDockAssignmentRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/admin/appointmentAssign",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || models_1.DockAssignmentToJSON(requestParameters.dockAssignment),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.DockAssignmentFromJSON(body), text); };
    }
    return config;
}
/**
*/
function partialUpdateDockAssignment(requestParameters, requestConfig) {
    return partialUpdateDockAssignmentRaw(requestParameters, requestConfig);
}
exports.partialUpdateDockAssignment = partialUpdateDockAssignment;
/**
 */
function partialUpdateLocationsAdminRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling partialUpdateLocationsAdmin.');
    }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/admin/locations/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || models_1.LocationToJSON(requestParameters.location),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.LocationFromJSON(body), text); };
    }
    return config;
}
/**
*/
function partialUpdateLocationsAdmin(requestParameters, requestConfig) {
    return partialUpdateLocationsAdminRaw(requestParameters, requestConfig);
}
exports.partialUpdateLocationsAdmin = partialUpdateLocationsAdmin;
/**
 */
function retrieveAdminUserRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling retrieveAdminUser.');
    }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/auth/admin/users/{id}").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.UserFromJSON(body), text); };
    }
    return config;
}
/**
*/
function retrieveAdminUser(requestParameters, requestConfig) {
    return retrieveAdminUserRaw(requestParameters, requestConfig);
}
exports.retrieveAdminUser = retrieveAdminUser;
/**
 */
function retrieveAppointmentRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling retrieveAppointment.');
    }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.dockGroupingIdLocationId !== undefined) {
        queryParameters['dock_grouping_id__location_id'] = requestParameters.dockGroupingIdLocationId;
    }
    if (requestParameters.datetime !== undefined) {
        queryParameters['datetime'] = requestParameters.datetime;
    }
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/appointments/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.AppointmentFromJSON(body), text); };
    }
    return config;
}
/**
*/
function retrieveAppointment(requestParameters, requestConfig) {
    return retrieveAppointmentRaw(requestParameters, requestConfig);
}
exports.retrieveAppointment = retrieveAppointment;
/**
 */
function retrieveDockGroupingRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling retrieveDockGrouping.');
    }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/dockgroupings/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.DockGroupingFromJSON(body), text); };
    }
    return config;
}
/**
*/
function retrieveDockGrouping(requestParameters, requestConfig) {
    return retrieveDockGroupingRaw(requestParameters, requestConfig);
}
exports.retrieveDockGrouping = retrieveDockGrouping;
/**
 */
function retrieveLocationRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling retrieveLocation.');
    }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/locations/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.LocationFromJSON(body), text); };
    }
    return config;
}
/**
*/
function retrieveLocation(requestParameters, requestConfig) {
    return retrieveLocationRaw(requestParameters, requestConfig);
}
exports.retrieveLocation = retrieveLocation;
/**
 */
function retrieveLocationsAdminRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling retrieveLocationsAdmin.');
    }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/admin/locations/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.LocationFromJSON(body), text); };
    }
    return config;
}
/**
*/
function retrieveLocationsAdmin(requestParameters, requestConfig) {
    return retrieveLocationsAdminRaw(requestParameters, requestConfig);
}
exports.retrieveLocationsAdmin = retrieveLocationsAdmin;
/**
 */
function retrieveShipperRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling retrieveShipper.');
    }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/auth/shippers/{id}").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.ShipperFromJSON(body), text); };
    }
    return config;
}
/**
*/
function retrieveShipper(requestParameters, requestConfig) {
    return retrieveShipperRaw(requestParameters, requestConfig);
}
exports.retrieveShipper = retrieveShipper;
/**
 */
function retrieveUserRaw(requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/auth/users/current/",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.UserFromJSON(body), text); };
    }
    return config;
}
/**
*/
function retrieveUser(requestConfig) {
    return retrieveUserRaw(requestConfig);
}
exports.retrieveUser = retrieveUser;
/**
 */
function updateAdminUserRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateAdminUser.');
    }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/auth/admin/users/{id}").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || models_1.UserToJSON(requestParameters.user),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.UserFromJSON(body), text); };
    }
    return config;
}
/**
*/
function updateAdminUser(requestParameters, requestConfig) {
    return updateAdminUserRaw(requestParameters, requestConfig);
}
exports.updateAdminUser = updateAdminUser;
/**
 */
function updateAppointmentRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateAppointment.');
    }
    var queryParameters = null;
    queryParameters = {};
    if (requestParameters.dockGroupingIdLocationId !== undefined) {
        queryParameters['dock_grouping_id__location_id'] = requestParameters.dockGroupingIdLocationId;
    }
    if (requestParameters.datetime !== undefined) {
        queryParameters['datetime'] = requestParameters.datetime;
    }
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/appointments/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || models_1.AppointmentToJSON(requestParameters.appointment),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.AppointmentFromJSON(body), text); };
    }
    return config;
}
/**
*/
function updateAppointment(requestParameters, requestConfig) {
    return updateAppointmentRaw(requestParameters, requestConfig);
}
exports.updateAppointment = updateAppointment;
/**
 */
function updateDockAssignmentRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: runtime.Configuration.basePath + "/api/scheduler/admin/appointmentAssign",
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || models_1.DockAssignmentToJSON(requestParameters.dockAssignment),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.DockAssignmentFromJSON(body), text); };
    }
    return config;
}
/**
*/
function updateDockAssignment(requestParameters, requestConfig) {
    return updateDockAssignmentRaw(requestParameters, requestConfig);
}
exports.updateDockAssignment = updateDockAssignment;
/**
 */
function updateLocationsAdminRaw(requestParameters, requestConfig) {
    if (requestConfig === void 0) { requestConfig = {}; }
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateLocationsAdmin.');
    }
    var queryParameters = null;
    var headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    var _a = requestConfig.meta, meta = _a === void 0 ? {} : _a;
    var config = {
        url: (runtime.Configuration.basePath + "/api/scheduler/admin/locations/{id}/").replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
        meta: meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || models_1.LocationToJSON(requestParameters.location),
    };
    var requestTransform = requestConfig.transform;
    if (requestTransform) {
        config.transform = function (body, text) { return requestTransform(models_1.LocationFromJSON(body), text); };
    }
    return config;
}
/**
*/
function updateLocationsAdmin(requestParameters, requestConfig) {
    return updateLocationsAdminRaw(requestParameters, requestConfig);
}
exports.updateLocationsAdmin = updateLocationsAdmin;
