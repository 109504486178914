import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import { useMutation, useRequest } from 'redux-query-react';
import {
  createUser, logoutAction, initializeQuery, createTokenObtainPair, getAuthState, intializeLoggedOut, isValidToken
} from 'src/store/auth';
import { User, Configuration } from 'api-client'; 
import { useSelector } from 'src/store';
import { useDispatch } from 'react-redux';
import { isValid } from 'redux-form';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
  register: (user: User) => Promise<any>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const state = useSelector(getAuthState);
  const [{}, login] = useMutation(createTokenObtainPair);

  const dispatch = useDispatch();
  const logout = () => dispatch(logoutAction());

  const [{}, register] = useMutation(createUser);

  // If we have an accessToken, try to fetch the user details
  useRequest(initializeQuery());

  useEffect(() => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (!(isValidToken(accessToken)) && !state.isInitialised) {
      dispatch(intializeLoggedOut());
    }
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;