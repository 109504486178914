"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteToJSON = exports.InviteFromJSON = void 0;
var runtime_1 = require("../runtime");
function InviteFromJSON(json) {
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'createdDate': !runtime_1.exists(json, 'created_date') ? undefined : new Date(json['created_date']),
    };
}
exports.InviteFromJSON = InviteFromJSON;
function InviteToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'email': value.email,
        'created_date': value.createdDate === undefined ? undefined : value.createdDate.toISOString(),
    };
}
exports.InviteToJSON = InviteToJSON;
