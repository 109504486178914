"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse2003ToJSON = exports.InlineResponse2003FromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function InlineResponse2003FromJSON(json) {
    return {
        'count': !runtime_1.exists(json, 'count') ? undefined : json['count'],
        'next': !runtime_1.exists(json, 'next') ? undefined : json['next'],
        'previous': !runtime_1.exists(json, 'previous') ? undefined : json['previous'],
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
        'limit': !runtime_1.exists(json, 'limit') ? undefined : json['limit'],
        'results': !runtime_1.exists(json, 'results') ? undefined : json['results'].map(_1.LocationFromJSON),
    };
}
exports.InlineResponse2003FromJSON = InlineResponse2003FromJSON;
function InlineResponse2003ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'offset': value.offset,
        'limit': value.limit,
        'results': value.results === undefined ? undefined : value.results.map(_1.LocationToJSON),
    };
}
exports.InlineResponse2003ToJSON = InlineResponse2003ToJSON;
