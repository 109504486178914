"use strict";
// tslint:disable
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DockAssignmentToJSON = exports.DockAssignmentFromJSON = void 0;
var _1 = require("./");
function DockAssignmentFromJSON(json) {
    return {
        'appointments': json['appointments'].map(_1.DockAssignmentAppointmentsFromJSON),
    };
}
exports.DockAssignmentFromJSON = DockAssignmentFromJSON;
function DockAssignmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    return {
        'appointments': value.appointments.map(_1.DockAssignmentAppointmentsToJSON),
    };
}
exports.DockAssignmentToJSON = DockAssignmentToJSON;
