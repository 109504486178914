import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import components from './layouts/DocsLayout/mdx';
import { Tune } from '@material-ui/icons';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/contactUs',
    component: lazy(() => import('src/views/home/ContactUsView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: [
      '/register',
      '/invite',
    ],
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    exact: false,
    guard: GuestGuard,
    path: '/activate',
    component: lazy(() => import('src/views/auth/ActivationCodeView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/scheduler',
        component: lazy(() => import('src/views/scheduler/ListShippersView'))
      },
      {
        exact: true,
        path: '/app/scheduler/shipper/:shipperId',
        component: lazy(() => import('src/views/scheduler/ShipperView'))
      },
      {
        exact: true,
        path: '/app/scheduler/shipper/:shipperId/appointment/:dockGroupingId',
        component: lazy(() => import('src/views/scheduler/AppointmentView')),
      },
      {
        path: '/app/scheduler/appointments/:appointmentId/edit',
        component: lazy(() => import('src/views/scheduler/AppointmentView')),
      },
      {
        exact: true,
        path: '/app/scheduler/appointments',
        component: lazy(() => import('src/views/scheduler/MyAppointments')),
      },
      {
        path: '/app/scheduler/appointments/:appointmentId/',
        exact: true,
        component: lazy(() => import('src/views/scheduler/AppointmentDetailsView')),
      },
      {
        exact: true,
        path: '/app/admin/appointments',
        component: lazy(() => import('src/views/shipperAdmin/appointments')),
      },
      {
        exact: false,
        path: '/app/admin/locations',
        component: lazy(() => import('src/views/shipperAdmin/locations')),
      },
      {
        exact: false,
        path: '/app/admin/assignment',
        component: lazy(() => import('src/views/shipperAdmin/assignment')),
      },
      {
        exact: false,
        path: '/app/admin/users',
        component: lazy(() => import('src/views/shipperAdmin/users')),
      },
      {
        exact: false,
        path: '/app/admin/reports',
        component: lazy(() => import('src/views/shipperAdmin/reports')),
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/scheduler" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
