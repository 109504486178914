import jwtDecode from 'jwt-decode';
import {
  createTokenObtainPair as createTokenObtainPairRaw,
  createInvitedUser as createInvitedUserRaw,
  createUser as createUserRaw,
  createActivationCode as createActivationCodeRaw,
  retrieveUser,
  User
} from 'api-client';
import get from 'lodash/get';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { TramRounded } from '@material-ui/icons';


export const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  try {
    const decoded: any = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
  
    return decoded.exp > currentTime;
  } catch(err) {
    return false;
  }


};

export const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

export const createTokenObtainPair = (email: string, password: string) => {
  return createTokenObtainPairRaw({
    tokenObtainPair: { email, password } 
  }, {
    transform: responseBody => {
      const { access, user } = responseBody;

      setSession(access);

      return {
        user,
        authInfo: {
          isAuthenticated: true,
          isInitialised: true,
        },
      };
    },
    update: {
      user: (prev: any, next: any) => next,
      authInfo: (prev: any, next: any) => next,
    }
  });
}

export const createUser = (user: User) => {
  return createUserRaw({ user });
}

const loginQueryConfig = {
  transform: responseBody => {
    const { access, user } = responseBody;

    setSession(access);

    return {
      user,
      authInfo: {
        isAuthenticated: true,
        isInitialised: true,
      },
    };
  },
  update: {
    user: (prev: any, next: any) => next,
    authInfo: (prev: any, next: any) => next,
  }
};

export const createInvitedUser = (user: User, inviteCode: string) => {
  return createInvitedUserRaw({ 
    user,
    id: inviteCode,
  }, loginQueryConfig);
}

export const createActivationCode = (email: string, verificationCode: string) => {
  return createActivationCodeRaw({
    activationCode: {
      email,
      verificationCode,
    },
  }, loginQueryConfig);
}

export const logoutAction = createAction('LOGOUT');
export const intializeLoggedOut = createAction('INITIALIZE_LOGGED_OUT')

export const logoutReducer = createReducer({} as any, {
  [logoutAction.type]: (state) => {
    setSession(null);
    const entities = {
      ...state.entities,
      authInfo: {
        isAuthenticated: false,
        isInitialised: true,
      },
      user: null,
    }
    return {
      ...state,
      entities
    };
  },
  [intializeLoggedOut.type]: (state) => {
    const entities = {
      ...state.entities,
      authInfo: {
        isAuthenticated: false,
        isInitialised: true,
      },
      user: null,
    }
    return {
      ...state,
      entities
    };
  }
});

export const initializeQuery = () => {
  const accessToken = window.localStorage.getItem('accessToken');

  return (accessToken && isValidToken(accessToken)) ? retrieveUser({
    transform: responseBody => { 
      return {
        user: responseBody,
        authInfo: {
          isAuthenticated: true,
          isInitialised: true,
        }
      }

    },
    update: {
      user: (prev: any, next: any) => next,
      authInfo: (prev: any, next: any) => next,
    }
  }) : null;
}


export const getAuthState = (state: any) => ({
  user: state.entities.user,
  ...get(state, 'entities.authInfo', {
    isAuthenticated: false,
    isInitialised: false,
  })
})

export const getUser = (state: any) => (state.entities.user || null);